.CustomProjects {
 // overflow: hidden;
  width: 100%;
  padding: 20px 0;
  margin-top:25px;

  .card {
    width: 100%;
    margin: 20px 0;
    
    background-color: #fff !important;
    box-shadow: none;
    .titlepro {
      margin-top: 10px;
      p {
        font-size: 20px;
        font-weight: bold;
        font-family: "poppins";
        color: #000;
        @media screen and (max-width: 991px) {
          font-size: 18px;
          font-weight: 600;
          line-height: 15px;
        }
        @media screen and (max-width: 474px) {
          font-size: 18px;
          font-weight: 600;
          line-height: 15px;
        }
      }
      span {
        
        margin-right: 10px;
        padding: 5px 15px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 700;
        font-family: "Poppins";
        background: linear-gradient(90deg, #7ff593, #68eaae, #53dfc7);
        @media screen and (max-width: 1200px) {
          font-size: 14px;
        }
        @media screen and (max-width: 568px) {
          font-size: 12px;
        }
      }
    }
  }
}


.nodata {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-top: 30px;
}
